const Footer = () => {
	return (
		<>
			<div className="bg-[#000] py-5">
				<p className="text-center text-white capitalize">© copyright 2020 Oprime Tech. all rights reserved.</p>
			</div>
		</>
	)
}

export default Footer